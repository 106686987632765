import {put} from "@redux-saga/core/effects";
import {clearTokensAction} from "../slices/tokensSlice";
import { logOutUserAction} from "../slices/userSlice";
import {Router} from "@remix-run/router";
import {getContext} from "redux-saga/effects";
import {SIGN_IN} from "../../routes/paths";
import {uiChangeIsShowMessageAction, uiUpdateTimerAction} from "../slices/uiSlice";
import {signInClearStateAction} from "../slices/signInSlice";
import {signUpClearStateAction} from "../slices/signUpSlice";

export function* logoOutSaga(){
    try {
        yield put(logOutUserAction());
        yield put(clearTokensAction());
        yield put(signInClearStateAction())
        yield put(signUpClearStateAction())
        yield localStorage.clear();
        yield put(uiUpdateTimerAction(0));
        yield put(uiChangeIsShowMessageAction(false));
        const router: Router | unknown = yield (getContext('router'));
        yield (router as Router).navigate(SIGN_IN,{replace: true})
    }
    catch (e) {
        console.log(e)
    }


}
