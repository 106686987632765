import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {validation} from "../../../schemas/validation.schemas";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {
    addUserDetailsAction,
    updateUserDetailsAction,
    userDetailsSelector,
    userEmailSelector
} from "../../../store/slices/userSlice";
import {useFormikRef} from "../../../hooks";
import {IUserDetails} from "../../../models";

export const UserDetailsForm = () => {
    const dispatch = useDispatch();
    const email = useSelector(userEmailSelector);
    const userDetails = useSelector(userDetailsSelector);

    const onSubmit = (values: IUserDetails) => {
        dispatch(addUserDetailsAction({
            name: values.name,
            surname: values.surname,
            sex: values.sex,
            birthday: values.birthday
        }));
        dispatch(updateUserDetailsAction());

    }
    const formikRef = useFormikRef(
        userDetails,
        validation.userDetails(),
        onSubmit,
    );

    const formik = formikRef.current


    return (
        <Box sx={{maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
            <Typography fontSize='xx-large' component="h1" variant="h5">
                User Details
            </Typography>
            <Box className='form' component='form' sx={{mt: 1}} onSubmit={formik.handleSubmit}>
                <TextField
                    margin='normal'
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    type='text'
                    name='email'
                    placeholder='email'
                    disabled={true}
                    value={email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name) || Boolean(formik.errors.name)}
                    helperText={formik.errors.name as string}
                />

                <TextField
                    margin='normal'
                    id='outlined-basic'
                    label='Name'
                    variant='outlined'
                    type='text'
                    name='name'
                    placeholder='Name'
                    value={formik.values.name ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.errors.name as string}
                />

                <TextField
                    margin='normal'
                    id='outlined-basic'
                    label='Surname'
                    variant='outlined'
                    type='text'
                    name='surname'
                    placeholder='Surname'
                    value={formik.values.surname ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.surname && Boolean(formik.errors.surname)}
                    helperText={formik.errors.surname as string}
                />

                <Box sx={{display: 'flex', gap: '10px', margin: '20px 0'}}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disableFuture
                            format="DD/MM/YYYY"
                            value={formik.values.birthday ?? dayjs()}
                            label="Birthday"
                            onChange={(value) => formik.setFieldValue('birthday', value)}
                        />
                    </LocalizationProvider>

                    <FormControl sx={{ minWidth: 130 }}>
                        <InputLabel>Sex</InputLabel>

                        <Select
                            id="sex"
                            name='sex'
                            value={formik.values.sex}
                            label="Sex"
                            onChange={formik.handleChange}
                        >
                            <MenuItem value={formik.values.sex}>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                            <MenuItem value={'Helicopter black hawk'}>Helicopter black hawk</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

                <Button color='success' variant='contained' type='submit'>Save</Button>
             </Box>
        </Box>
    )
}
