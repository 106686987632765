import {Axios, AxiosResponse} from "axios";
import {IChangeEmailBody, IError, ILetterResponse } from "../models";
import {transformError} from "../helpers";
import $api from "../http";
import {checkResponse} from "./checkResponse";

class EmailService {
    static readonly sendLetterPath: string = `/user/email/change`;
    static readonly changeEmailPath: string = `/user/email/change`;
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;
    }

    public async change(data: IChangeEmailBody): Promise<AxiosResponse<any> | IError> {
        return  this.http.put(EmailService.changeEmailPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async sendLatter(data: {email: string, accessType?: string}): Promise<AxiosResponse<ILetterResponse> | IError> {
        return  this.http.post(EmailService.sendLetterPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }

}

export const emailService = new EmailService($api);
