import React, {FC} from 'react'
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import './style.scss'

let rules = [
    'Be no less then eight characters.',
    'A mix of upper and lower case letters.',
    'At least one digit and a special character.'
]

export const PasswordRulesList:FC = () => {
    return (
        <Box className='list-wrapper' >
            <Box className='list-header'>
                <InfoIcon fontSize='medium'></InfoIcon>
                <Typography sx={{textDecoration: 'underline'}} >
                    Your password must contain:
                </Typography>
            </Box>

            <List sx={{fontSize:"8px"}}>
                {rules.map((el,i) => (
                    <ListItem key={i} sx={{padding: '.2rem 0'}} divider={i !== rules.length - 1}>
                        <ListItemText
                            primary={el}
                            primaryTypographyProps={{fontSize: '.8rem'}}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}
