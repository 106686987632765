import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {errorsClear} from "../store/slices/errorSlice";

export const useClearErrorsNavigate = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();

    return (path:string) => {
        navigate(path)
        dispatch(errorsClear())
    }
}
