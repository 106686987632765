import {createSlice} from "@reduxjs/toolkit";
import {IAction, IError, IErrorsState, IStoreState} from "../../models";

export const initialState: IErrorsState = {
    signin: null,
    signup: null,
    sendLetter: null,
    confirmEmail: null,
    userDetails: null,
    updatePassword: null,
    changePassword: null,
    changeEmail: null,
    uploadFile: null,
    ui: null,
}

const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        errorsSignInUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.signin = action.payload;
        },
        errorsSignUpUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.signup = action.payload;
        },
        errorsSendLetterUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            console.log(action.payload)
            state.sendLetter = action.payload;
            console.log(state.sendLetter)
        },
        errorsUserDetailsUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.userDetails = action.payload;
        },
        errorsUpdatePasswordUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.updatePassword = action.payload;
        },
        errorsChangePasswordUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.changePassword = action.payload;
        },
        errorsChangeEmailUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.changeEmail = action.payload;
        },
        errorsConfirmEmailUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.confirmEmail = action.payload;
        },
        errorsUploadFileAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.uploadFile = action.payload;
        },
        errorsUiUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.ui = action.payload;
        },
        errorsClear(state: IErrorsState) {
            state.signin = null;
            state.signup = null;
            state.sendLetter = null;
            state.confirmEmail = null;
            state.userDetails = null;
            state.updatePassword = null;
            state.changePassword = null;
            state.changeEmail = null;
            state.uploadFile = null;
            state.ui = null;
        }

    }
});

export const errorsReducer = errorsSlice.reducer;

export const {
    errorsConfirmEmailUpdateAction,
    errorsUpdatePasswordUpdateAction,
    errorsChangePasswordUpdateAction,
    errorsUserDetailsUpdateAction,
    errorsSendLetterUpdateAction,
    errorsSignUpUpdateAction,
    errorsSignInUpdateAction,
    errorsUiUpdateAction,
    errorsChangeEmailUpdateAction,
    errorsClear,
    errorsUploadFileAction
} = errorsSlice.actions;

export const errorsSignInSelector = (state: IStoreState) => state.errors.signin;
export const errorsSignUpSelector = (state: IStoreState) => state.errors.signup;
export const errorsSendLetterSelector = (state: IStoreState) => state.errors.sendLetter;
export const errorsUserDetailsSelector = (state: IStoreState) => state.errors.userDetails;
export const errorsUploadFileSelector = (state: IStoreState) => state.errors.uploadFile;
export const errorsUpdatePasswordSelector = (state: IStoreState) => state.errors.updatePassword;
export const errorsChangePasswordSelector = (state: IStoreState) => state.errors.changePassword;
export const errorsChangeEmailSelector = (state: IStoreState) => state.errors.changeEmail;
export const errorsConfirmEmailSelector = (state: IStoreState) => state.errors.confirmEmail;
export const errorsUiSelector = (state: IStoreState) => state.errors.ui;
