import { call ,put, select} from "@redux-saga/core/effects";
import {uiChangeLoadingAction, uiChangeMessageAction} from "../../slices/uiSlice";
import {IError, IUserDetails} from "../../../models";
import { userDetailsSelector} from "../../slices/userSlice";
import {errorsUiUpdateAction, errorsUserDetailsUpdateAction} from "../../slices/errorSlice";
import {userService} from "../../../services/UserService";
import {updateTokensSaga} from "../token-sagas/updateTokensSaga";

export function* updateUserDetailsSaga(): Generator {
    try {
        let data: IUserDetails | unknown =  yield select(userDetailsSelector);
        yield put(uiChangeLoadingAction(true))
        yield put(errorsUserDetailsUpdateAction(null))
        yield put(errorsUiUpdateAction(null))

        let response = yield call(() => userService.addUserDetails(
            {
                name: (data as IUserDetails).name,
                surname: (data as IUserDetails).surname
            }));
        if ((response as Object).hasOwnProperty('errorCode')) {
            throw response;
        }
        else {
            yield call(() => updateTokensSaga())
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Updated!',
                desc: `Congratulations you successfully updated your details`,
                role: 'inf'
            }))
        }

    }
    catch (e) {
        if ((e as IError).status) {
            yield  put(errorsUserDetailsUpdateAction( e as IError))

        }
        else {
            yield put(errorsUiUpdateAction(e as IError))

        }

    }
    finally {
        yield put(uiChangeLoadingAction(false))

    }
}
