import {IError} from "../models";

// const createField = (el:string, error: IError,form: string) => {
//     let fields = {
//         updatePassword: {
//             CurrentPassword: {password: error.messages.CurrentPassword},
//             NewPassword: {newPassword: error.messages.NewPassword},
//             Password: {password: error.messages.Password},
//         },
//         Email: {email: error.messages.Email},
//         ActionError: {email: error.messages.ActionError},
//     }
//     // @ts-ignore
//     return fields[form][el]
// }


export const setError = (formik: any, error: IError | null,form:string) => {
    if (error && error.messages) {
        let fields = {
            updatePassword: {
                CurrentPassword: {password: error.messages.CurrentPassword},
                NewPassword: {newPassword: error.messages.NewPassword},
                Password: {password: error.messages.Password},
            },
            changeEmail: {
                Key: {password: error.messages.Key},
                Password: {password: error.messages.Password},
                ActionError: {password: error.messages.ActionError}
            },
            confirmEmail: {
                Key: {key: error.messages.Key},
                ActionError: {key: error.messages.ActionError}
            },
            sendLetter: {
                Email: {email: error.messages.Email},
                ActionError: {email: error.messages.ActionError}
            },
            changePassword: {
                Key: {password: error.messages.Key}
            },
            signIn: {
                Password: {password: error.messages.Password},
                Email: {email: error.messages.Email},
                ActionError: {email: error.messages.ActionError}
            },
            signUp: {
                ActionError: {email: error.messages.ActionError}
            }
        }



     let keys = Object.keys(error.messages);
     keys.forEach(el => {
         // @ts-ignore
         formik.setErrors(fields[form][el])
     })
    }
}
