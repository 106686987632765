import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store,persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import {injectStore} from "./http";
import {IStore} from "./models";


injectStore((store as unknown as IStore))

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
      <PersistGate loading={<>Loading persist</>} persistor={persistor}>
          <App></App>
      </PersistGate>
  </Provider>

);

reportWebVitals();
