import {AxiosError, AxiosResponse} from "axios";
import {IError} from "../models";

const errorsCodes  =[
    'None',
    'InternalServerError',
    'ActionFailed',
    'NotFound',
    'BadRequest',
    'Conflict',
    'ActionCanceled',
    'Unauthorized',
    'Forbidden',
    'TokenNotValid',
    'RefreshRequired',
    'ERR_NETWORK'
]

export const transformError = (error: AxiosError): IError => {
    console.log(error)
    let errorObject: IError | null = null;

    if (error.response) {
        let {data, status} = (error.response as AxiosResponse);
        errorObject = {
            status: status,
            errorCode: errorsCodes.indexOf(data.errorCode),
            messages: data.messages
        };
    }
    else  {
        let axError: AxiosError = (error.toJSON() as AxiosError);

        errorObject = {
            status: axError.status ? axError.status : null,
            errorCode: errorsCodes.indexOf(axError.name),
            messages: {Axios: axError.message}
        }
    }



    return errorObject as IError

}

