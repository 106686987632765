import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {call, put} from "@redux-saga/core/effects";
import {fileService} from "../../../services/FileService";
import {filesAddImages, filesChangeLoad} from "../../slices/filesSlice";
import {IError, IFileResponse} from "../../../models";

export function* getFilesListSaga(): Generator {
    try {
        yield put(errorsUiUpdateAction(null));
        yield put(filesChangeLoad(true));
        let resp = yield call(() => fileService.get());

        if ((resp as Object).hasOwnProperty('errorCode')) {
            throw resp;
        }
        else {
            yield put(filesAddImages(resp as IFileResponse[]));
        }
    }
    catch (e) {
        yield put(errorsUiUpdateAction(e as IError));
    }
    finally {
        yield put(filesChangeLoad(false));

    }
}
