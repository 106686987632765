import {IError, IStore} from "../../models";
import {AxiosError} from "axios";
import {logoutAction, updateTokensAction} from "../../store/slices/tokensSlice";

export const handleError = (error: IError | AxiosError,store: IStore) => {
    if(error) {
        // @ts-ignore
        if (error.response && error.response.status === 401) {
            store.dispatch(logoutAction());
        }
        // @ts-ignore
        if(error && error.status && error.status === 400) {

            // @ts-ignore
            if (error.response.data.errorCode === "RefreshRequired") {
                store.dispatch(updateTokensAction());
            }

        }
    }


}
