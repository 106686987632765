import {ChangeEventHandler, FC, useRef, useState} from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import './style.scss'
import {Box, Typography} from "@mui/material";

type TProps = {
    onFileChange: (e: any) => void
}


export const DropFileInput:FC<TProps> = (props) => {
    const wrapperRef = useRef(null);

    // @ts-ignore
    const onDragEnter = () => (wrapperRef.current).classList.add('dragover');

    // @ts-ignore
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    // @ts-ignore
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    // @ts-ignore
    return (
        <>
            <Box
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <Box className="drop-file-input__label">
                    <AddAPhotoIcon />
                    <Typography sx={{pt: 2}}> Click <br/> or <br/> Drag & Drop your files here</Typography>
                </Box>
                <input type="file" accept='image/*'  value="" onChange={props.onFileChange} />
            </Box>
        </>
    );
}
