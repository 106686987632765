import {FC, useState} from "react";
import {Box,Drawer, IconButton, Typography} from "@mui/material";
import { useSelector} from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import './style.scss'
import {MenuList} from "../menu-list";
import { userSelector} from "../../store/slices/userSlice";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {CustomContainer} from "../customContainer";
import Logo from '../../assets/images/logo_reflum.png'


export const Header: FC = () => {
    let [showMenu, setShowMenu] = useState(false);
    let user = useSelector(userSelector);

    const toggleList = (newOpen: boolean) => () => {
        setShowMenu(newOpen);
    };

    return (
        <Box className='header' component={"header"}>
            <CustomContainer>

                <Box className='header__inner'>
                    <IconButton className='header__menu-btn' onClick={toggleList(true)}>

                        <MenuIcon className='header__menu-icon'></MenuIcon>
                    </IconButton>
                    <Drawer open={showMenu} onClose={toggleList(false)}>
                        {<MenuList showMenu={setShowMenu}></MenuList>}
                    </Drawer>

                    <Box className='header__user-details'>
                        <AccountCircleIcon fontSize={"large"} />
                        <Typography>
                            {user.details.name ? user.details.name : user.email}
                        </Typography>
                    </Box>
                    <img style={{maxWidth: '100px'}} src={Logo} alt="logo"/>
                </Box>
            </CustomContainer>
        </Box>
    )
}
export default Header;
