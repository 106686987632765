import {userService} from "../../../services/UserService";
import {call, put} from "@redux-saga/core/effects";

import {AxiosResponse} from "axios";
import {IError, IUserDetails} from "../../../models";
import {addUserDetailsNameAction, addUserDetailsSurnameAction } from "../../slices/userSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {uiChangeLoadingAction} from "../../slices/uiSlice";

export function* getUserDetailsSaga (): Generator {

    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null))
        let resp: unknown | IUserDetails = yield call(() => userService.getUserDetails());
        if (!(resp as Object).hasOwnProperty('errorCode')) {
            yield put(addUserDetailsNameAction((resp as IUserDetails).name))
            yield put(addUserDetailsSurnameAction((resp as IUserDetails).surname))
        }
        else {
            throw resp;
        }
    }
    catch (e) {
        yield put(errorsUiUpdateAction(e as IError))
    }
    finally {
        yield put(uiChangeLoadingAction(false));
    }

}
