import { call, put} from '@redux-saga/core/effects'
import {authService} from '../../../services/AuthService';
import {SIGN_IN} from "../../../routes/paths";
import { getContext } from "redux-saga/effects"
import {Router} from "@remix-run/router";
import {IAction, IAuthBody} from "../../../models";
import {uiChangeLoadingAction} from "../../slices/uiSlice";
import {signUpAddEmailAction,} from "../../slices/signUpSlice";
import {signInAddEmailAction} from "../../slices/signInSlice";
import {errorsSignUpUpdateAction, errorsUiUpdateAction} from "../../slices/errorSlice";

export function* signUpSaga(action: IAction<IAuthBody>): Generator<{type:String,payload?: any}> | Generator {
    try {

        yield put(uiChangeLoadingAction(true));
        yield put(errorsSignUpUpdateAction(null))
        yield put(errorsUiUpdateAction(null))

        yield put(signUpAddEmailAction(action.payload.email));
        yield put(signInAddEmailAction(action.payload.email));

        let response = yield call(() => authService.signUp(action.payload));

        if (!(response as Object).hasOwnProperty('errorCode')) {

            const router: Router | unknown = yield getContext('router');
            yield (router as Router).navigate(SIGN_IN,{replace: true});
        }
        else {
            throw response;
        }

    }
    catch (e: any) {
        if (!e.status) {
            yield put(errorsUiUpdateAction(e))
        }
        else {
            yield put(errorsSignUpUpdateAction(e));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false))
    }
}
