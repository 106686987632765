import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {EMAIL, UPDATE_PASSWORD, USER_DETAILS} from "../../routes/paths";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import {FC} from "react";
import {useClearErrorsNavigate} from "../../hooks";

type TProps = {
    showMenu : (arg: boolean) => void
}

export const SettingsList: FC<TProps> = ({showMenu}) => {
    const navigate = useClearErrorsNavigate();
    const handleClick = (route: string) => {
        showMenu(false)
        navigate(route)
    }
    return (
        <List disablePadding>

            <ListItem>
                <ListItemButton onClick={() => handleClick(USER_DETAILS)}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText>
                        User details
                    </ListItemText>
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton onClick={() => handleClick(EMAIL)} >
                    <ListItemIcon>
                        <AlternateEmailIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Change email
                    </ListItemText>
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton onClick={() => handleClick(UPDATE_PASSWORD)}>
                    <ListItemIcon>
                        <KeyIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Change password
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </List>
    )
}
