import {IAction, IError} from "../../../models";
import {call, put} from "@redux-saga/core/effects";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {fileService} from "../../../services/FileService";
import {filesAddImage} from "../../slices/filesSlice";
import {uiChangeIsShowMessageAction, uiChangeMessageAction} from "../../slices/uiSlice";
import {getFilesListSaga} from "./getFilesListSaga";

export function* deleteFileSaga(action: IAction<string>): Generator {
    try {
        yield put(errorsUiUpdateAction(null));
        yield put(uiChangeIsShowMessageAction(false));
        let resp = yield call(() => fileService.delete(action.payload))

        if ((resp as Object).hasOwnProperty('errorCode')) {
            throw resp;
        }
        else {
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Deleted!',
                desc: 'Image was deleted',
                role: 'inf'
            }))
            yield put(filesAddImage(null));
            yield call(() => getFilesListSaga());
        }
    }
    catch (e) {
        yield put(errorsUiUpdateAction(e as IError));

    }
}
