import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, ISettingsState, IStoreState} from "../../models";

const initialState: ISettingsState = {
    updatePassword: {
        currentPassword: '',
        newPassword: '',
    },
    changePassword: {
      key: '',
      password: '',
    },
    changeEmail: {
        confirmationKey: '',
        password: ''
    },
    email: ''
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        addChangeNewPasswordAction(state: ISettingsState, action: IAction<string>) {
            state.changePassword.password = action.payload;
        },
        addChangeKeyPasswordAction(state: ISettingsState, action: IAction<string>) {
            state.changePassword.key = action.payload;
        },
        addUpdateCurrPasswordAction(state: ISettingsState, action: IAction<string>) {
            state.updatePassword.currentPassword = action.payload;
        },
        addUpdateNewPasswordAction(state: ISettingsState, action: IAction<string>) {
            state.updatePassword.newPassword = action.payload;
        },
        addChangeKeyEmailAction(state: ISettingsState, action: IAction<string>) {
            console.log('asdasd')
            state.changeEmail.confirmationKey = action.payload;
        },
        addChangeEmailPasswordAction(state: ISettingsState, action: IAction<string>) {
            state.changeEmail.password = action.payload;
        },
        addEmailAction(state: ISettingsState, action: IAction<string>) {
            state.email = action.payload;
        }

    }
})

export const settingsReducer = settingsSlice.reducer;
export const {
    addChangeNewPasswordAction,
    addChangeKeyPasswordAction,
    addUpdateCurrPasswordAction,
    addUpdateNewPasswordAction,
    addChangeKeyEmailAction,
    addChangeEmailPasswordAction,
    addEmailAction
} = settingsSlice.actions;

export const CHANGE_PASSWORD = 'settingsSlice/changePasswordAction';
export const CHANGE_EMAIL = 'settingsSlice/changeEmailAction';
export const UPDATE_PASSWORD = 'settingsSlice/updateUserPasswordsAction';
export const SEND_LETTER = 'settingsSlice/sendResetPasswordAction';

export const changePasswordAction = createAction(CHANGE_PASSWORD);
export const changeEmailAction = createAction(CHANGE_EMAIL);
export const updatePasswordAction = createAction(UPDATE_PASSWORD);
export const sendLetterAction = createAction(SEND_LETTER,payload => ({payload}));


export const settingsUpdatePasswordSelector = (state: IStoreState) => state.settings.updatePassword;
export const settingsUpdatePasswordPasswordSelector = (state: IStoreState) => state.settings.updatePassword.currentPassword;
export const settingsStoreSelector = (state: IStoreState) => state.settings;
export const settingsEmailSelector = (state: IStoreState) => state.settings.email;
export const settingsChangePasswordSelector = (state: IStoreState) => state.settings.changePassword;
export const settingsChangePasswordKeySelector = (state: IStoreState) => state.settings.changePassword.key;
export const settingsChangeEmailKeySelector = (state: IStoreState) => state.settings.changeEmail.confirmationKey;
export const settingsChangeEmailPasswordSelector = (state: IStoreState) => state.settings.changeEmail.password;
export const settingsChangeEmailSelector = (state: IStoreState) => state.settings.changeEmail;
