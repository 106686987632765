import { configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {sagas} from "./sagas";
import {
    userReducer,
    tokensReducer,
    uiReducer,
    signInReducer,
    signUpReducer,
    errorsReducer,
    settingsReducer, filesReducer
} from "./slices";
import storage from 'redux-persist/lib/storage'
import {router} from "../routes";
import {
    persistReducer,
    persistStore,
    } from "redux-persist";




const persistConfigTokens = {
    key: 'tkns',
    storage,
};
const persistConfigUser = {
    key: 'userClaims',
    storage,
};



const persistedTokens = persistReducer(persistConfigTokens,tokensReducer);
const persistedUser = persistReducer(persistConfigUser,userReducer);

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        tokens: persistedTokens,
        user: persistedUser,
        signIn: signInReducer,
        signUp: signUpReducer,
        ui: uiReducer,
        errors: errorsReducer,
        settings: settingsReducer,
        files: filesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: false,
            serializableCheck: false
        }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
})

// For routes from sagas
sagaMiddleware.setContext({
    router
});

//Activate sagas
sagaMiddleware.run(sagas)

// Band store with localStorage
const persistor = persistStore(store)

export {
    store,
    persistor,
}
