import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IFileResponse, IFilesState, IStoreState} from "../../models";

const initialState: IFilesState = {
    isLoad: false,
    image: null,
    images: [],
    percentage: 0,
    isUploaded: false,
    isUploading: false,
};


const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {

        filesAddImage(state: IFilesState,action: IAction<IFileResponse | null>) {
            state.image = action.payload;
        },
        filesAddImages(state: IFilesState,action: IAction<IFileResponse[] | []>) {
            state.images = action.payload;
        },
        filesChangeLoad(state: IFilesState,action: IAction<boolean>) {
            state.isLoad = action.payload;
        },
        filesChangePercentage(state: IFilesState,action: IAction<number>) {
            state.percentage = action.payload;
        },
        filesChangeIsUploading(state: IFilesState,action: IAction<boolean>) {
            state.isUploading = action.payload;
        },
        filesChangeIsUploaded(state: IFilesState,action: IAction<boolean>) {
            state.isUploaded = action.payload;
        }
    }
});

export const filesReducer = filesSlice.reducer;

export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS';
export const GET_FILES = 'GET_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const getFilesAction = createAction(GET_FILES,);
export const deleteFileAction = createAction(DELETE_FILE,payload => ({payload}));
export const uploadPhotosAction = createAction(UPLOAD_PHOTOS,payload => ({payload}));

export const {
    filesAddImage,
    filesAddImages,
    filesChangeLoad,
    filesChangePercentage,
    filesChangeIsUploaded,
    filesChangeIsUploading,
} = filesSlice.actions;

export const filesIsUploadedSelector = (state: IStoreState) => state.files.isUploaded;
export const filesIsUploadingSelector = (state: IStoreState) => state.files.isUploading;
export const filesImageSelector = (state: IStoreState) => state.files.image;
export const filesImagesSelector = (state: IStoreState) => state.files.images;
export const filesLoadSelector = (state: IStoreState) => state.files.isLoad;
export const filesPercentageSelector = (state: IStoreState) => state.files.percentage;
