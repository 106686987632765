import {FC} from "react";
import {Container} from "@mui/material";
import './style.scss'

export const CustomContainer: FC<any> = (props) => {
    return (
        <Container component='main' maxWidth='lg' className='container' >
            {props.children}
        </Container>
    )
}
