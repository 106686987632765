export const SIGN_UP = '/signUp';
export const SIGN_IN = '/signIn';
export const CONFIRM = '/confirm';
export const USER_DETAILS = '/settings/details';
export const UPDATE_PASSWORD = '/settings/password';
export const EMAIL = '/settings/email';
export const EMAIL_CHANGE = '/settings/email/change';
export const FORGOT_PASSWORD = '/forgot';
export const FORGOT_PASSWORD_CHANGE = '/forgot/change';
export const ACTION = '/action/:p';
export const REDIRECT = '/redirect';
export const FILES = '/files';
export const FILE = '/file';
export const MERCHANT = '/merchant';
export const HOME = '/';
export const FLOOD = '*';

