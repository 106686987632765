import {FC, useEffect, useState} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import './styles.scss'
import {useDispatch} from "react-redux";

type TProps = {
    percents: number,
    callback?: () => void;
}

export const CircleWithPercents:FC<TProps> = ({percents,callback}) => {
    let [value, setValue] = useState(percents);

    useEffect(() => {
        setValue(percents)
    },[percents])
    return (
            <Box className='circle-container'>
                <CircularProgress sx={{position: 'absolute', zIndex: '3'}} variant="determinate" value={percents} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${Math.round(value)}%`}</Typography>
                </Box>
            </Box>
    )
}
