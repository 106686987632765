import {FC} from "react";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    addChangeKeyEmailAction,
    addChangeKeyPasswordAction,
    settingsChangeEmailKeySelector
} from "../store/slices/settingsSlice";
import {CONFIRM, EMAIL_CHANGE, FORGOT_PASSWORD_CHANGE, HOME, SIGN_IN} from "./paths";
import {tokensEmailIsConfirmedSelector} from "../store/slices/tokensSlice";

export const ActionRedirectRoute: FC= () => {
    let {p} = useParams()
    let dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get('key');
    let path = SIGN_IN;

    if (p === 'p' && key) {
        dispatch(addChangeKeyPasswordAction((key as string)))
        path = FORGOT_PASSWORD_CHANGE;
    }
    else if (p === 'e' && key) {
        dispatch(addChangeKeyEmailAction((key as string)))
        path = EMAIL_CHANGE;
    }
    return <Navigate to={path} />
};

export const RedirectRoute: FC= () => {
    let key = useSelector(settingsChangeEmailKeySelector);
    let emailConf = useSelector(tokensEmailIsConfirmedSelector);
    let path = HOME;

    if (key) {
        path = EMAIL_CHANGE;
    }

    else if(!emailConf) {
        path = CONFIRM;
    }

    return <Navigate to={path} />
};
