import {IError, IUpdatePasswordBody, ISignInResponse} from "../../../models";
import {put, call, select} from "@redux-saga/core/effects";
import {uiChangeLoadingAction, uiChangeMessageAction} from "../../slices/uiSlice";
import {passwordService} from "../../../services/PasswordService";
import {AxiosResponse} from "axios/index";
import {errorsUiUpdateAction, errorsUpdatePasswordUpdateAction} from "../../slices/errorSlice";
import {
    addUpdateCurrPasswordAction,
    addUpdateNewPasswordAction,
    settingsUpdatePasswordSelector
} from "../../slices/settingsSlice";

export function* updatePasswordSaga(): Generator {
    try {
        yield put(uiChangeLoadingAction(true))
        yield put(errorsUpdatePasswordUpdateAction(null))
        yield put(errorsUiUpdateAction(null))



        let data: IUpdatePasswordBody | unknown = yield select(settingsUpdatePasswordSelector)
        let resp:AxiosResponse<ISignInResponse> | IError | unknown  = yield call(() => passwordService.update((data as IUpdatePasswordBody)));

        if ((resp as Object).hasOwnProperty('errorCode')) {
            throw resp;
        }

        yield put(addUpdateCurrPasswordAction(''))
        yield put(addUpdateNewPasswordAction(''))
        yield put(uiChangeMessageAction({
            isShow: true,
            title: 'Updated!',
            desc: `Congratulations you successfully updated your password, please use them for sign in`,
            role: 'inf'
        }))


    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsUpdatePasswordUpdateAction(e as IError))
        }
        else {
            yield put(errorsUiUpdateAction(e as IError))
        }


    }
    finally {
        yield put(uiChangeLoadingAction(false))
    }
}
