import {FC, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import './style.scss';
import {errorsUiSelector, errorsUiUpdateAction} from "../../store/slices/errorSlice";


export const ErrorBanner: FC = () => {
    const error = useSelector(errorsUiSelector);
    let [show, setShow] = useState(false);
    let [errorsMessage, setErrorsMessage] = useState('');
    let dispatch = useDispatch()

    useEffect(() => {
        if (error && error.messages) {
            let key: string = Object.keys(error.messages)[0];
            // @ts-ignore
            setErrorsMessage(error.messages[key])
            setShow(true)
        }
        setTimeout(() => {
            setShow(false)
            dispatch(errorsUiUpdateAction(null))
        },2000)
    }, [error,errorsMessage])

    return (
        <Box className={ show ? 'error-banner show' : 'error-banner' }>
            {errorsMessage}
        </Box>
    )
}

export default ErrorBanner;
