import {Axios, AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {ISignInResponse, IAuthBody, IError, ILetterResponse} from "../models/";
import $api from "../http";
import {transformError} from "../helpers";
import {checkResponse} from "./checkResponse";





class AuthService{
    static readonly signUpPath: string = `/SignUp`
    static readonly signInPath: string = `/auth/signin`
    static readonly confirmEmailPath = `/User/email/confirm`
    static readonly resendLatterPath = `/User/email/confirm/resend`
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;
    }
    public async signUp(data: IAuthBody): Promise<AxiosResponse | IError | AxiosError>  {
        return this.http.post(AuthService.signUpPath,{email: data.email, password: data.password}).then(res =>  checkResponse(res)).catch(e =>  transformError(e))
    }
    public async signIn(data: IAuthBody): Promise<AxiosResponse<any> | IError> {
        return this.http.post<ISignInResponse>(AuthService.signInPath,{
            email: data.email,
            password: data.password,
            rememberMe: data.rememberMe,
            accessType: data.accessType,
        }).then(res =>  checkResponse(res)).catch(e =>  transformError(e))
    }
    public async confirmEmail(data: string): Promise<AxiosResponse<any> | IError> {
        return this.http.post<AxiosRequestConfig>(AuthService.confirmEmailPath,{key: data})
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async sendLatter(): Promise<AxiosResponse<ILetterResponse> | IError > {
        return this.http.post(`${AuthService.resendLatterPath}`)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))

    }
}

export const authService = new AuthService($api)
