import {Axios, AxiosResponse} from "axios";
import {IError} from "../models";
import $api from "../http";
import {transformError} from "../helpers";
import {IUserDetailsBody} from "../models/requests/IUserDetailsBody";
import {checkResponse} from "./checkResponse";



class UserService{
    static readonly userDetailsPath: string = `/user/details`;
    static readonly userTypesPath: string = `/Utilities/user/type`;
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;
    }
    public async addUserDetails(data: IUserDetailsBody): Promise<AxiosResponse<any> | IError> {
        return  this.http.post(UserService.userDetailsPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async getUserDetails(): Promise<AxiosResponse<IUserDetailsBody> | IError> {
        return  this.http.get(UserService.userDetailsPath)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }

}

export const userService = new UserService($api);
