import {Axios} from "axios";
import $api from "../http";
import {IError, IFileResponse} from "../models";
import {AxiosResponse} from "axios";
import {checkResponse} from "./checkResponse";
import {transformError} from "../helpers";


let dispatcher: any;

export const getDispatcherLoader = (disp: any) => {
    dispatcher = disp;
}

class FileService {
    static readonly filePath = '/File';
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;

    }

    public async get(): Promise<AxiosResponse<IError | IFileResponse[]>> {
        return this.http.get(FileService.filePath)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async post(data: any, callback?: any): Promise<AxiosResponse<IError | IFileResponse>> {
        const controller = new AbortController()
        const onUploadProgress = (event: any) => {
            const percentage = Math.round((100 * event.loaded) / event.total);
            console.log(percentage)
            if (dispatcher && callback) {
                dispatcher(callback(percentage))
                if (percentage >= 100) {
                    dispatcher(callback(0))
                }
            }
        };

        setTimeout(() => controller.abort(),500000)

        const headers = {
            "File-Format": data.format,
            "File-Dimensions": data.sizes.length > 1 ? data.sizes.join(';') : data.sizes[0],
        }
        console.log(data)
        return this.http.post(
            FileService.filePath,
            data.data,
            {
                headers: {...headers,
                    "Content-Type": `image/${headers["File-Format"]}`
                },
                signal: controller.signal,
                onUploadProgress})
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async delete(id:string) {
        return this.http.delete(`${FileService.filePath}?id=${id}`)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
}

export const fileService = new FileService($api);
