import {
    tokensExpireSelector,
    tokensJwtSelector,
    tokensLogedTimeSelector,
    updateTokensAction
} from "../../store/slices/tokensSlice";
import {checkExpire} from "../../helpers";
import {InternalAxiosRequestConfig} from "axios/index";
import {IStore} from "../../models";




export const makeRefresh = (config: InternalAxiosRequestConfig, store: IStore) => {
    if (!(config.url as string).includes('refresh')) {
        let expire = tokensExpireSelector(store.getState());
        let lastUpdate = tokensLogedTimeSelector(store.getState());
        if (expire && checkExpire(+expire,lastUpdate)) {
            store.dispatch(updateTokensAction());
        }
    }
}


export const writeAcces = (config: InternalAxiosRequestConfig,store: IStore) => {
    let token = tokensJwtSelector(store.getState());
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}
