
import { call, put, select} from '@redux-saga/core/effects'
import {addJwtAction, addrefreshTokenAction, tokensRefreshSelector} from "../../slices/tokensSlice";
import {refreshService} from "../../../services/RefreshService";
import {IError, IRefreshBody, ISignInResponse} from "../../../models";
import {decodeJwtSaga} from "./decodeJwtSaga";
import  {AxiosResponse} from "axios";
import {errorsUiUpdateAction} from "../../slices/errorSlice";


export function* updateTokensSaga():Generator<{type:String,payload?: any}> | Generator<any> {

    try {
        put(errorsUiUpdateAction(null))
        let refresh = yield select(tokensRefreshSelector);
        let res:AxiosResponse<ISignInResponse> | unknown = yield call(() => refreshService.refresh(refresh as IRefreshBody));

        yield put(addrefreshTokenAction((res as ISignInResponse).refreshToken));
        yield put(addJwtAction((res as ISignInResponse).jwt));
        yield call(() => decodeJwtSaga((res as ISignInResponse).jwt));
    }
    catch (e) {
        put(errorsUiUpdateAction(e as IError))

    }
    finally {
    }
}
