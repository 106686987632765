import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormHelperText
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from '@mui/icons-material/Cancel';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    filesChangeIsUploaded,
    filesChangePercentage,
    filesIsUploadedSelector, filesIsUploadingSelector,
    filesPercentageSelector,
    uploadPhotosAction
} from "../../store/slices/filesSlice";
import {CircleWithPercents} from "../circleWithPercents";
import {DropFileInput} from "../dropFileInput";
import './style.scss'
import {uiChangeMessageAction} from "../../store/slices/uiSlice";

const checkboxValues = ['Original', 'Small','Medium','Large'];
const ValidFormats = ['gif','png','jpg','jpeg'];

export const AddFileControllers = () => {
    const [preview, setPreview] = useState<string | ArrayBuffer | null>('');
    const [sizes, setSizes] = useState<string[]>([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [format, setFormat] = useState('');
    const [file, setFile] = useState({});
    const [isError,setIsError] = useState(false);
    const dispatch = useDispatch();
    const percentage = useSelector(filesPercentageSelector);
    const isUploaded = useSelector(filesIsUploadedSelector);
    const isUploading = useSelector(filesIsUploadingSelector);






    const addSize = (value: string,checked: boolean) => {
        if (checked) {
            setSizes(sizes.concat(value))
        }
        else if (!checked) {
            setSizes(sizes.filter(el => el != value))
        }
    };

    const cancelImage = () => {
      setPreview('');
      setSizes([]);
      setFile('');
      setFormat('')
    };

    const makePreview = (e: any) => {
        const format = e.target.files[0].name.split('.').pop().toLowerCase();
        if (ValidFormats.includes(format)) {
            setIsError(false);
            setFormat(format)
            setFile(e.target.files[0]);
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.readyState === 2) {
                    setPreview(fileReader.result);
                }
            };
            // @ts-ignore
            fileReader.readAsDataURL(e.target.files[0])
        }
        else {
            setIsError(true)
        }
    };

    useEffect(() => {
        setIsDisabled((preview as string).length === 0);

        if (isUploaded) {
            cancelImage()
            dispatch(uiChangeMessageAction({
                isShow: true,
                title: 'Added! :)',
                desc: 'Your picture was added!',
                role: 'inf'
            }))
            dispatch(filesChangeIsUploaded(false));
        }
        return;
    },[preview,isUploaded])

    const uploadPhotos = () => {
        if (percentage === 0 && !isUploading) {
            const data = {
                format: format,
                sizes: sizes,
                data: file,
            }
            dispatch(uploadPhotosAction({data: data, callback: cancelImage}))
        }
    };


    return (
        <Box className='controllers-wrap'>

            <Box className='controllers-inputs-wrap'>
                <Box className='controllers-preview'>
                    {preview ?
                        <Box className='preview-img__wrap'>
                            {
                                percentage > 0 && percentage < 100 ?
                                <CircleWithPercents  percents={percentage} />
                                : null
                            }
                            <img src={(preview as string)} alt="preview"/>
                        </Box>
                        : <DropFileInput onFileChange={makePreview} />
                    }
                </Box>

                <ButtonGroup className={preview ? 'controllers-btns-group' : 'controllers-btns-group hide'}>
                    <Button
                        variant='contained'
                        color={"error"}
                        onClick={cancelImage}
                        startIcon={<CancelIcon/>}
                        disabled={percentage > 0 && isUploading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color={'success'}
                        disabled={sizes.length < 1}
                        onClick={uploadPhotos}
                        startIcon={percentage > 0 || isUploading ? <CircularProgress size={15} color='inherit' /> : <FileUploadIcon/> }>
                        Upload
                    </Button>
                </ButtonGroup>
                {isError && <FormHelperText sx={{pl: 1}} error={isError}>Not valid file format valid formats are: PNG, GIF, JPG</FormHelperText>}
            </Box>

            <Box>
                <FormGroup  sx={{display: 'flex'}}>
                    {checkboxValues.map(el => (
                        <FormControlLabel
                            key={el}
                            label={el}
                            control={
                                <Checkbox
                                    checked={sizes.includes(el.toLowerCase())}
                                    value={el.toLowerCase()}
                                    disabled={isDisabled || isError}
                                    onChange={(e) => addSize(e.target.value,e.target.checked)}
                                />
                            }
                        />
                    ))}

                </FormGroup>
            </Box>
        </Box>
    )
}
