import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IStoreState, IUserDetails, IUserState} from "../../models";
import {TUserType} from "../../models/UserClaims";

const initialState: IUserState= {
    id: '',
    email: '',
    type: 'Client',
    details: {
        name: '',
        surname: '',
        sex: '',
        birthday: null,
    }
};


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUserEmailAction(state: IUserState,action:IAction<string>) {
            state.email = action.payload;
        },
        changeUserTypeAction(state: IUserState,action:IAction<TUserType>) {
            state.type = action.payload;
        },
        addUserIdAction(state:IUserState,action:IAction<string>) {
            state.id = action.payload;
        },
        addUserDetailsAction(state:IUserState,action:IAction<IUserDetails>) {
            state.details = action.payload;
        },
        addUserDetailsNameAction(state:IUserState,action:IAction<string>) {
            state.details.name = action.payload;
        },
        addUserDetailsSurnameAction(state:IUserState,action:IAction<string>) {
            state.details.surname = action.payload;
        },
        logOutUserAction(state:IUserState) {
            state.id = '';
            state.email = '';
            state.details = {
                    name: '',
                    surname: '',
                    sex: '',
                    birthday: null,
            }
        }

    }

})

export const userReducer = userSlice.reducer;

// ACTIONS

export const {
    addUserEmailAction,
    addUserIdAction,
    logOutUserAction,
    addUserDetailsAction,
    addUserDetailsNameAction,
    addUserDetailsSurnameAction,
    changeUserTypeAction,
} = userSlice.actions;

export const UPDATE_DETAILS= 'userSlice/updateUserDetailsAction';
export const GET_DETAILS = 'userSlice/getUserDetailsAction';

export const updateUserDetailsAction = createAction(UPDATE_DETAILS);
export const getUserDetailsAction = createAction(GET_DETAILS);



// SELECTOR

export const userSelector = (state: IStoreState) => state.user;
export const userEmailSelector = (state: IStoreState) => state.user.email;
export const userDetailsSelector = (state: IStoreState) => state.user.details;
export const userTypeSelector = (state: IStoreState) => state.user.type;

