import {takeEvery, takeLatest} from 'redux-saga/effects';

// *SAGAS
import {signUpSaga} from "./auth-sagas/signUpSaga";
import {signInSaga} from "./auth-sagas/signInSaga";
import {updateTokensSaga} from "./token-sagas/updateTokensSaga";
import {confirmEmailSaga} from "./auth-sagas/confirmEmailSaga";
import {logoOutSaga} from "./logoOutSaga";
import {updateUserDetailsSaga} from "./user-sagas/updateUserDetailsSaga";
import {updatePasswordSaga} from "./user-sagas/updatePasswordSaga";
import {sendLetterSaga} from "./user-sagas/sendLetterSaga";
import {changePasswordSaga} from "./auth-sagas/changePasswordSaga";
import {getUserDetailsSaga} from "./user-sagas/getUserDetailsSaga";
import {changeEmailSaga} from "./user-sagas/changeEmailSaga";
import {uploadFileSaga} from "./files-sagas/uploadFileSaga";
import {deleteFileSaga} from "./files-sagas/deleteFileSaga";
import {getFilesListSaga} from "./files-sagas/getFilesListSaga";

// TYPES
import {LOGOUT, UPDATE_TOKENS,UPDATE_CONFIRM} from "../slices/tokensSlice";
import {DELETE_FILE, UPLOAD_PHOTOS, GET_FILES} from '../slices/filesSlice'
import {CHANGE_EMAIL, CHANGE_PASSWORD, SEND_LETTER, UPDATE_PASSWORD} from "../slices/settingsSlice";
import {SIGN_UP} from "../slices/signUpSlice";
import {SIGN_IN} from "../slices/signInSlice";
import {GET_DETAILS, UPDATE_DETAILS} from "../slices/userSlice";


//MAIN SAGA

export function* sagas(){
  yield takeEvery(SIGN_UP,signUpSaga);
  yield takeEvery(SIGN_IN,signInSaga);
  yield takeLatest(UPDATE_TOKENS, updateTokensSaga);
  yield takeLatest(UPDATE_CONFIRM, confirmEmailSaga);
  yield takeLatest(LOGOUT, logoOutSaga);
  yield takeLatest(UPDATE_DETAILS,updateUserDetailsSaga);
  yield takeLatest(UPDATE_PASSWORD,updatePasswordSaga);
  yield takeLatest(SEND_LETTER,sendLetterSaga);
  yield takeLatest(CHANGE_PASSWORD,changePasswordSaga);
  yield takeLatest(GET_DETAILS,getUserDetailsSaga);
  yield takeLatest(CHANGE_EMAIL,changeEmailSaga);
  yield takeLatest(UPLOAD_PHOTOS,uploadFileSaga);
  yield takeLatest(DELETE_FILE,deleteFileSaga)
  yield takeLatest(GET_FILES, getFilesListSaga)
}
