import {Axios, AxiosResponse} from "axios";
import {IChangePasswordBody, IError, ILetterResponse, IUpdatePasswordBody} from "../models";
import {transformError} from "../helpers";
import $api from "../http";
import {checkResponse} from "./checkResponse";

class PasswordService {
    static readonly updatePasswordPath: string = `/user/password`;
    static readonly resetPassPath: string = `/user/password/reset`;
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;
    }
    public async update(data: IUpdatePasswordBody): Promise<AxiosResponse<any> | IError> {
        return  this.http.put(PasswordService.updatePasswordPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async sendLatter(data: {email: string}): Promise<AxiosResponse<ILetterResponse> | IError> {
        return  this.http.post(PasswordService.resetPassPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
    public async change(data: IChangePasswordBody): Promise<AxiosResponse<any> | IError> {
        return  this.http.put(PasswordService.resetPassPath,data)
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }
}

export const passwordService = new PasswordService($api);
