import {IFileResponse} from "../../models";
import {memo} from "react";
import {Box, Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";

type TProps = {
    data: IFileResponse,
    deleteCard?: (id: string) => void
    goTo?: (image: IFileResponse) => void
};


export const ImageCard = memo<TProps>(function ImageCard({data,deleteCard,goTo}) {

    const deleteImage = () => {
        if (deleteCard) {
            deleteCard(data.id)
        }
    }
    const showMore = () => {
        if (goTo) {
            goTo(data)
        }
    }

    return (
        <Card sx={{width: 325,}}>
            <CardMedia
                component='img'
                alt='some image'
                height='250'
                src={`${data.link}/${data.sizes[0].name}`}
            />
            <CardContent>
              <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                  <Typography>
                        Sizes:
                  </Typography>
                  {data.sizes.map((el) => (<Typography key={el.name}>{el.name},</Typography>))}
              </Box>
            </CardContent>
            <CardActions>
                <Button size='small' onClick={showMore}>Show more</Button>
                <Button size='small' color='error' onClick={deleteImage}>Delete</Button>
            </CardActions>
        </Card>
    )
})
