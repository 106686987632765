import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, ISignInState, IStoreState} from "../../models";

const initialState: ISignInState = {
    email: '',
    password: '',
    rememberMe: false,
    accessType: 'Client'
}

export const signInSlice = createSlice({
    name: 'signIn',
    initialState,
    reducers: {
        signInAddEmailAction: (state: ISignInState, action: IAction<string>) => {
            state.email = action.payload
        },
        signInAddPasswordAction: (state: ISignInState, action: IAction<string>) => {
            state.password = action.payload
        },
        signInClearStateAction: (state: ISignInState) => {
            state.password = '';
            state.email = '';
        }
    }
})

export const signInReducer = signInSlice.reducer;

export const {signInAddEmailAction, signInAddPasswordAction, signInClearStateAction} = signInSlice.actions;
export const SIGN_IN = 'signInSlice/signInAction';
export const signInAction = createAction(SIGN_IN,payload => ({payload}))

export const signInEmailSelector = (state: IStoreState) => state.signIn.email
export const signInStoreSelector = (state: IStoreState) => state.signIn;
