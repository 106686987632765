import {Axios, AxiosResponse} from "axios";
import {IRefreshBody, IError} from "../models";
import $api from "../http";
import {transformError} from "../helpers";
import {checkResponse} from "./checkResponse";



class RefreshService{
    static readonly refreshPath: string = `/auth/refresh`;
    private http: Axios;

    constructor(http: Axios) {
        this.http = http;
    }
    public async refresh(data: IRefreshBody): Promise<AxiosResponse<any> | IError> {
        return  this.http.post(RefreshService.refreshPath,{token: data})
            .then(res =>  checkResponse(res))
            .catch(e =>  transformError(e))
    }

}

export const refreshService = new RefreshService($api);

