import {IAction, IError, IFileResponse} from "../../../models";
import {fileService} from "../../../services/FileService";
import {call, put} from "@redux-saga/core/effects";
import {errorsUiUpdateAction, errorsUploadFileAction} from "../../slices/errorSlice";
import {
    filesChangeIsUploaded, filesChangeIsUploading,
    filesChangePercentage,
} from "../../slices/filesSlice";
import {getFilesListSaga} from "./getFilesListSaga";

export function* uploadFileSaga(action: IAction<any>): Generator {
    try {

        yield put(errorsUiUpdateAction(null));
        yield put(errorsUploadFileAction(null));
        yield put(filesChangeIsUploaded(false));
        yield put(filesChangeIsUploading(true));



        const resp: unknown | IFileResponse = yield call(() => fileService.post(action.payload.data, filesChangePercentage));
        if ((resp as Object).hasOwnProperty('errorCode')) {
            throw resp;
        }
        else {
            yield put(filesChangeIsUploaded(true))
            yield call(() => getFilesListSaga());
        }
    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsUploadFileAction(e as IError))
        }
        else {
            yield put(errorsUiUpdateAction(e as IError));

        }
    }
    finally {
        yield put(filesChangeIsUploading(false));
    }
}
